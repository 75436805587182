@import "./theme/radioButton.module.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500&display=swap');
input[type=checkbox] {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  cursor: pointer;

}


.slide-down{
  transform: translateY(90%);
  transition: transform 0.3s ease-out;
}
.slide-up{
  transform: translateY(0%);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

input[type=checkbox]:checked {
  background-color: #021BFA !important;
  cursor: pointer;
}

.PrivateSwitchBase-input {
  height: 100% !important;
  width: 100% !important;
}

.css-1m9pwf3 {
  height: 100% !important;
  width: 100% !important;
}


#pricing-table .box:nth-child(odd){
  background-color: rgba(2, 27, 250, 0.05)
}

.checkbox:checked, .checkbox[checked="true"], .checkbox[aria-checked=true] {
  
  background-attachment:scroll;
  background-color: #021BFA !important;
}

::-webkit-scrollbar {
  width: 4px;

}

 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #BDBDBD; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa; 
}


@tailwind base;
@tailwind components;
@tailwind utilities;


.entry-header {
  display: none !important;
}

html {
  background: #f5f5f5 !important;
}

body {
  background: #f5f5f5 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background: #f5f5f5 !important;
}

// #DICOM-wallet-ohif {
//   background: #000 !important;
// }

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  border-radius: 100px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  border-radius: 100px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  border-radius: 100px;
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2.5px;
  bottom: 2.2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #1DD3B0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1DD3B0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.force-padding-5 {
  padding: 5px !important
}

.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 160px; 
  height: 1.2em; 
  white-space: nowrap;
}

.headerFontBold .MuiDataGrid-columnHeaderTitle{
  font-weight: bold;
}
.MuiDataGrid-columnHeader:focus, 
.MuiDataGrid-cell:focus{
  outline: none!important;
}

.mobileActionBarShadow{
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.2);
}

.dividerline{
  width: 1px;
  height: 25px;

  background: #D9D9D9;
}

.hideseperatorline .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight{
  display: none;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);  // Optional: adds a dim background
}


.no-select-element {
  -webkit-touch-callout: none; /* Safari */
        -webkit-user-select: none; /* Chrome */     
           -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; 
}
.table-font{
  font-family: 'Roboto', sans-serif;
 
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  
}


// svg animation 
.demo1 {
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.ui-success,.ui-error {
	width: 100px; height: 100px;
	margin: 40px;
	// border:1px solid #eee;
}

.ui-success{
	&-circle {
		stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
    stroke-dashoffset: 260.75219024795285px;
    transform: rotate(220deg);
    transform-origin: center center;
		stroke-linecap: round;
		animation: ani-success-circle 1s ease-in both;
	}
	&-path {
		stroke-dasharray: 60px 64px;
    stroke-dashoffset: 62px;
		stroke-linecap: round;
		animation: ani-success-path .4s 1s ease-in both;
	}
}

@keyframes ani-success-circle {
	to{stroke-dashoffset: 782.2565707438586px;}
}

@keyframes ani-success-path {
	0% {stroke-dashoffset: 62px;}
	65% {stroke-dashoffset: -5px;}
	84%{stroke-dashoffset: 4px;}
	100%{stroke-dashoffset: -2px;}
}

.ui-error{
	&-circle{
		stroke-dasharray:260.75219024795285px, 260.75219024795285px;
		stroke-dashoffset: 260.75219024795285px;
		animation: ani-error-circle 1.2s linear;
	}
	&-line1{
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line .15s 1.2s linear both;
	}
	&-line2{
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line .2s .9s linear both;
	}
}

@keyframes ani-error-line{
	to { stroke-dashoffset: 0; }
}

 @keyframes ani-error-circle {
		0% {
				stroke-dasharray: 0, 260.75219024795285px;
				stroke-dashoffset: 0;
		}
		35% {
				stroke-dasharray: 120px, 120px;
				stroke-dashoffset: -120px;
		}
		70% {
				stroke-dasharray: 0, 260.75219024795285px;
				stroke-dashoffset: -260.75219024795285px;
		}
		100% {
				stroke-dasharray: 260.75219024795285px, 0;
				stroke-dashoffset: -260.75219024795285px;
		}
}




