// .theme-radio {
//     @apply dark:bg-slate-800 dark:hover:bg-slate-700 dark:border-slate-700 dark:text-slate-100 bg-white peer-checked:text-slate-900 dark:peer-checked:text-slate-100  peer-checked:border-dicom-primary-100 peer-checked:bg-dicom-primary-100/5 dark:peer-checked:bg-dicom-primary-100/5
// }

.theme-radio {
    @apply  bg-white peer-checked:text-slate-900  peer-checked:border-dicom-primary-100 peer-checked:bg-dicom-primary-100/5
}

.theme-radio-selected {
    @apply dark:border-dicom-primary-100 border-dicom-primary-100 dark:text-slate-100 bg-white
}